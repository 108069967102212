<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-18 23:32:22
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2024-01-09 11:15:23
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\serviceProfile\index.vue
 * @Description: 
-->
<template>
	<div class="content">
		<div class="household-head head-shadow">
			<div class="household-head-title">查询信息</div>
			<div class="flex">
				<div class="">
					<div class="household-head-form">
						<div>选择小区/门牌</div>
						<el-select class="search-household-input ml-15" v-model="searchForm.area" placeholder="请选择小区"
							clearable>
							<el-option v-for="item in areaList" :key="item.code" :value="item.code"
								:label="item.name"></el-option>
						</el-select>
						<el-input class="household-head-form-input" placeholder="请输入楼幢"
							v-model="searchForm.bding"></el-input>
						<el-input class="household-head-form-input" placeholder="请输入单元"
							v-model="searchForm.unit"></el-input>
						<el-input class="household-head-form-input" placeholder="请输入房间号"
							v-model="searchForm.hnum"></el-input>
					</div>
					<div class="household-head-form mt-10">
						<div>服务内容</div>
						<el-input class="household-head-form-input ml-15" placeholder="请输入内容"
							v-model="searchForm.searchKey"></el-input>
						<div class="ml-15">时间范围</div>
						<el-date-picker class="ml-15" v-model="searchForm.searchTime" value-format="yyyy-MM-dd"
							type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" :picker-options="pickerOptions">
						</el-date-picker>
						<!-- <el-date-picker class="ml-15" v-model="searchForm.value1" type="daterange" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
				<el-radio-group v-model="searchForm.radio" class="ml-15">
					<el-radio :label="3">一周内</el-radio>
					<el-radio :label="6">一月内</el-radio>
					<el-radio :label="9">一季度内</el-radio>
				</el-radio-group> -->
					</div>
				</div>
				<div class="search-btns">
					<div>
						<md-button class="md-success btn" @click="searchList"><i class="el-icon-zoom-in el-icon"></i>查
							询</md-button>
					</div>
					<md-button class="btn md-default" @click="clearSearch"><i class="el-icon-refresh-right el-icon"></i>重
						置</md-button>
				</div>
			</div>
		</div>

		<div class="form-container" v-loading="loading">
			<!-- <div class="form-title md-title">近期服务档案（一周内）</div> -->
			<div class="form-operate">
				<div class="form-title md-title">近期服务档案</div>
				<div class="form-operate-btns">
					<md-button @click="addServiceProfile" class="md-success form-operate-btn"><i
							class="el-icon-plus mr-5"></i>新建服务</md-button>
					<!-- <md-button class="md-success form-operate-btn"><md-icon>add</md-icon>批量导入</md-button>
					<md-button class="md-success form-operate-btn"><md-icon>add</md-icon>下载</md-button> -->
				</div>
			</div>

			<div class="list-cont mt-10">
				<template v-if="tableData && tableData.length">
					<div class="list-item mb-20" v-for="item in tableData" :key="item.id">
						<div class="list-item-title">
							<div class="flex-1"><i class="el-icon-s-home mr-5"></i>{{ item.address }}</div>
							<div class="type">服务类型：{{ fwType[item.type] }}</div>
						</div>
						<el-descriptions title="" class="mt-20 ml-20 list-item-info" :column="3">
							<el-descriptions-item label="服务内容" :span="3">{{ item.content }}</el-descriptions-item>
							<el-descriptions-item label="服务对象">{{ item.nameList && item.nameList.join('、') || '--'
							}}<span v-if="item.otherName">{{ '、' + item.otherName }}</span></el-descriptions-item>
							<el-descriptions-item label="操作人员">{{ item.operator || '--' }}</el-descriptions-item>
							<el-descriptions-item label="服务时间">{{ item.time || '--' }}</el-descriptions-item>
							<el-descriptions-item label="图片音视频">
								<template v-if="item.files && item.files.length">
									<a class="download-file" v-for="(url, index) in item.files" :href="url" target="_blank"
										rel="noopener noreferrer">
										点击查看附件{{ index + 1 }}
									</a>
								</template>
								<span v-else>--</span>
							</el-descriptions-item>
							<!-- 图片音视频 -->
							<!-- <el-descriptions-item label="认领时间">--</el-descriptions-item>
							<el-descriptions-item label="经办人">--</el-descriptions-item> -->
							<!-- <el-descriptions-item label="备注">
							<el-tag size="small">学校</el-tag>
						</el-descriptions-item> -->
							<!-- <el-descriptions-item label="联系地址">{{ item.address }}</el-descriptions-item> -->
						</el-descriptions>
						<!-- <el-table :data="tableData" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
						<el-table-column type="expand">
							<template slot-scope="props">
								<div class="list-item-info">
									<div class="info-left">
										<div>服务内容：<span>阿三大苏打</span></div>
										<div class="mt-20">提交时间：<span>阿三大苏打</span> 参与者<span>阿斯顿</span></div>
									</div>
									<div class="info-right">
										<div>状态：已认领</div>
										<div class="mt-15">认领时间： 2018-10-22 14:22</div>
										<div class="mt-15">经办人：戴泳帽 </div>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="nickName" label="服务内容">
						</el-table-column>
						<el-table-column prop="mobile" label="当前状态" min-width="120">
							<template slot-scope="scope">
								{{ scope.row.mobile || '-' }}
							</template>
						</el-table-column>
						<el-table-column prop="phone" label="创建时间" min-width="120">
							<template slot-scope="scope">
								{{ scope.row.phone || '-' }}
							</template>
						</el-table-column>
						<el-table-column fixed="right" align="center" label="操作" width="240">
							<template slot-scope="scope">
								<el-button type="text">
									查看
								</el-button>
								<el-button type="text">
									编辑
								</el-button>
								<el-popconfirm confirm-button-text='确认' cancel-button-text='取消' icon="el-icon-info"
									icon-color="red" :title="`确认永久删除 ${scope.row.nickName} 吗？`">
									<el-button slot="reference" class="del-user" type="text">删除</el-button>
								</el-popconfirm>
							</template>
						</el-table-column>
					</el-table> -->
					</div>
				</template>
				<el-empty v-else description="暂无数据"></el-empty>
			</div>
			<el-pagination v-if="tableData && tableData.length" class="page text-r" layout="total, prev, pager, next"
				:current-page="page.pageNum" @current-change="updatePagination" :page-size="page.pageSize"
				:total="page.total">
			</el-pagination>
		</div>

		<el-dialog :title="dialogFormTitle" :visible.sync="dialogVisible" width="800px" :before-close="handleCloseForm">
			<el-form ref="dialogForm" :rules="familyRules" label-width="120px" :model="familyForm">
				<el-form-item label="选择小区/门牌">
					<el-select class="h-input mr-5" v-model="dialogSearchForm.area" placeholder="请选择小区" clearable>
						<el-option v-for="item in areaList" :key="item.code" :value="item.code"
							:label="item.name"></el-option>
					</el-select>
					<el-input class="h-input mr-5" placeholder="请输入楼幢" v-model="dialogSearchForm.bding"></el-input>
					<el-input class="h-input mr-5" placeholder="请输入单元" v-model="dialogSearchForm.unit"></el-input>
					<el-input class="h-input mr-5" placeholder="请输入房间号" v-model="dialogSearchForm.hnum"></el-input>
					<md-button class="md-success query-btn" @click="getHouseList"><i
							class="el-icon-zoom-in el-icon mr-5"></i>查
						询</md-button>
				</el-form-item>
				<el-form-item label="住址" prop="hid">
					<el-select v-model="familyForm.hid" placeholder="请选择住址" class="auth-select">
						<el-option v-for="item in hList" :key="item.id" :value="item.id" :label="item.address"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="类型" prop="type">
					<el-select v-model="familyForm.type" placeholder="请选择类型" class="auth-select">
						<el-option value="1" label="大厅服务"></el-option>
						<el-option value="2" label="走访记录"></el-option>
						<el-option value="3" label="社区活动"></el-option>
						<!-- <el-option v-for="item in typeArr" :key="item.id" :label="item.name" :value="item.id"></el-option> -->
					</el-select>
				</el-form-item>
				<el-form-item label="服务对象" prop="pidList">
					<el-select v-model="familyForm.pidList" multiple placeholder="请选择服务对象" class="auth-select">
						<el-option v-for="item in peopleList" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
					<el-input type="input" class="other-name-input" v-model="familyForm.otherName"
						placeholder="输入其他"></el-input>
				</el-form-item>
				<el-form-item label="服务时间" prop="time">
					<el-date-picker v-model="familyForm.time" type="date" value-format="yyyy-MM-dd" placeholder="请选择服务时间">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="服务内容" prop="content">
					<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="familyForm.content"
						placeholder="请输入服务内容"></el-input>
				</el-form-item>
				<el-form-item label="图片音视频" prop="phone">
					<el-upload class="upload-demo" drag action="/api/population/upload" :headers="{ token: authorToken }"
						:on-success="fileSuccess" multiple :file-list="fileList" :before-remove="beforeRemove">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
					</el-upload>
					<!-- <el-input v-model="familyForm.phone" placeholder="请输入工作电话"></el-input> -->
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCloseForm">取 消</el-button>
				<el-button v-loading="btnLoading" type="success" @click="saveFamilyRecord">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import * as API from '@/api/serviceProfile.js'
import { getPeopleList, getAreaList, getHouseList } from '@/api/peopleResult.js'
export default {
	data () {
		return {
			fileList: [],
			dialogSearchForm: {
				area: '',
				hnum: '',
				unit: '',
				bding: '',
			},
			searchForm: {
				area: '',
				hnum: '',
				unit: '',
				bding: '',
				searchKey: '',
				searchTime: [],
			},
			pickerOptions: {
				shortcuts: [{
					text: '最近一周内',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一月内',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一季度内',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			fwType: {
				1: '大厅服务',
				2: '走访记录',
				3: '社区活动',
			},
			typeArr: [],
			peopleList: [],
			authorToken: sessionStorage.authorToken,
			loading: true,
			familyForm: {
				address: '',
				content: '',
				pidList: [],
				nameList: [],
				type: '',
				files: [],
				hid: '',
				time: '',
				otherName: ''
			},
			familyRules: {
				time: [
					{
						required: true,
						message: '请选择服务时间',
						trigger: 'change'
					}
				],
				hid: [
					{
						required: true,
						message: '请选择户号',
						trigger: 'change'
					}
				],
				type: [
					{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}
				],
				pidList: [
					{
						required: true,
						message: '请选择服务对象',
						trigger: 'change'
					}
				],
				content: [
					{
						required: true,
						message: '请输入服务内容',
						trigger: 'blur'
					}
				],
			},
			btnLoading: false,
			dialogFormTitle: '新建服务档案',
			dialogVisible: false,
			tableData: [],
			page: {
				total: 0,
				pageNum: 1,
				pageSize: 10
			},
			areaList: [],
			hList: []
		}
	},
	watch: {
		'familyForm.hid': function (nVal) {
			if (nVal) {
				this.getPeopleList(nVal)
			}
		}
	},
	created () {
		this.getFamilyRecord()
		// this.getPeopleList()
		this.getAreaList()
		// this.getHouseList()
	},
	methods: {
		async getHouseList () {
			try {
				const data = await getHouseList({
					pageNum: 1,
					pageSize: 1,
					...this.dialogSearchForm
				})
				if (data.code === 200 && data.data) {
					this.hList = data.data.list || []
					this.$notify.success({
						title: '提示',
						message: '查询成功'
					})
				}
			} catch (error) {
				console.error(error)
			}
		},
		async getAreaList () {
			const data = await getAreaList()

			if (data.code === 200) {
				this.areaList = data.data || []
			}
		},
		async getPeopleList (id) {
			const data = await API.getFamilyList({
				hid: id
			})
			if (data.code === 200) {
				this.peopleList = data.data.populationList || []
			}
		},
		async getFamilyRecord () {
			try {
				this.loading = true
				const params = {
					...this.searchForm,
					pageSize: this.page.pageSize,
					pageNum: this.page.pageNum
				}
				params.searchTime = params.searchTime.join('|')
				const data = await API.getFamilyRecord(params)
				this.loading = false
				if (data.code === 200) {
					this.tableData = data.data.list || []
					this.page.total = data.data.total
				}
			} catch (error) {
				this.loading = false
				console.error('API-getFamilyRecord: ', error)
			}
		},
		async saveFamilyRecord () {
			this.$refs.dialogForm.validate(async valid => {
				if (valid) {
					this.btnLoading = true
					const nameList = []
					this.peopleList.forEach((item, index) => {
						if (item.id == this.familyForm.pidList[index]) {
							nameList.push(item.name)
						}
					})
					if (this.familyForm.pidList.indexOf('0') < 0 && !this.familyForm.otherName) {
						this.familyForm.pidList.push('0')
					}
					const data = await API.saveFamilyRecord({
						...this.familyForm,
						nameList //this.peopleList.filter(v => )
					})
					this.btnLoading = false
					if (data.code === 200) {
						this.$notify.success({
							title: '提示',
							message: '添加成功'
						})
						this.handleCloseForm()
						this.clearSearch()
						this.searchList()
					}
				}
			})
		},
		handleCloseForm () {
			this.familyForm = {
				address: '',
				content: '',
				pidList: [],
				nameList: [],
				type: '',
				files: [],
				hid: '',
				time: '',
				otherName: ''
			}
			this.$refs.dialogForm.clearValidate()
			this.dialogVisible = false
		},
		addServiceProfile () {
			this.dialogVisible = true
		},
		fileSuccess (res) {
			if (res.code === 200) {
				this.familyForm.files.push(res.data.link || '')
				this.$notify.success({
					title: '提示',
					message: '文件上传成功'
				})
			} else {
				this.$notify.error({
					title: '提示',
					message: '文件上传失败'
				})
			}
		},
		beforeRemove (file, fileList) {
			const index = this.familyForm.files.indexOf(file)
			this.familyForm.files.splice(index, 1)
		},
		searchList () {
			this.page.pageNum = 1
			this.getFamilyRecord()
		},
		clearSearch () {
			this.searchForm = {
				area: '',
				hnum: '',
				unit: '',
				searchKey: '',
				area: '',
				searchTime: [],
			}
			this.searchList()
		},
		/**
		 * 分页翻动
		 */
		updatePagination (page, pageSize, sort, sortOrder) {
			console.log('pagination has updated', page, pageSize, sort, sortOrder);
			// this.getUserList()
		},
	}
}
</script>

<style lang="scss" scoped>
.household-head {
	// display: flex;
	padding: 15px 20px;
	background-color: #fff;
	font-size: 16px;

	&-form {
		margin-top: 10px;
		display: flex;
		align-items: center;
		flex: 1;

		&-input {
			margin-left: 10px;
			max-width: 140px;
		}
	}

	// &-form2 {
	// 	display: flex;
	// }

	.btn {
		margin-left: 15px;
	}

	.el-icon {
		margin-right: 8px;
	}

	&-title {
		padding-left: 8px;
		border-left: 3px solid #36CC86;
		font-family: Source Han Sans CN;
		font-size: 20px;
		font-weight: 500;
	}
}

.form-container {
	padding: 15px 20px 20px;
	margin-top: 20px;
	background-color: #fff;

	.form-operate {
		// margin-top: 10px;
		display: flex;
		// padding: 0 20px;
		height: 60px;
		// line-height: 74px;
		align-items: center;

		.form-title {
			flex: 1;
		}

		// background: #fff;

		&-tag {
			width: 68px;
			height: 20px;
			border-radius: 10px;
			opacity: 1;
			font-size: 14px;
			text-align: center;
			color: #fff;
			/* 绿色纯色填充 */
			background: #36CC86;
		}

		&-name {
			margin-left: 8px;
			font-size: 18px;
			flex: 1;
		}

		&-btns {
			.form-operate-btn {
				margin-right: 12px;
			}
		}
	}
}

.list-item {
	padding-bottom: 20px;
	width: 100%;
	border-bottom: 1px solid #e6e6e6;

	.flex-1 {
		flex: 1;
	}

	&-title {
		display: flex;
		padding: 0 20px;
		background: #36AB64;

		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.03);
		height: 46px;
		line-height: 46px;
		color: #fff;
		font-size: 18px;
		font-weight: 500;
	}

	.type {
		font-size: 16px;
	}
}

.h-input {
	width: 120px;
}

.query-btn {
	margin: 0 !important;
	font-size: 16px;
}

.list-item-info {
	font-size: 16px;
}

.download-file {
	color: #4CAF50 !important;
}

.other-name-input {
	margin-left: 5px;
	width: 160px;
}

.search-btns {
	margin-left: 5%;
	padding-left: 3%;
	border-left: 1px solid #E5E6EB;
}
</style>